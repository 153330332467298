import React, {useContext} from "react"
import PropTypes from "prop-types"
import ReviewSlider from "./reviewSlide/ReviewSlider"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../context/LayoutContext"
import GoogleRbox from "./GoogleRbox"

type Props = {
  reviews: any
}

const GoogleReviews: React.FC<Props> = ({reviews}) => {
	const {t} = useTranslation("common")
	const {isMobile} = useContext(LayoutContext)

	return (
		<>
			{reviews ? (
				<section className="container mt-5 mb-5">
					<div className={`row ${!isMobile && "text-center"}`}>
						<Typography variant={`${isMobile ? "bodyLgBlack" : "heading2Black"}`}
							semanticTag="h3"
							className={`col-12 ${isMobile ? "pt-3" : "pt-5"}`}
							style={{fontSize: isMobile ? 28 : ""}}>
              8000+ {t("Kund* innen")}
						</Typography>
						<div className="text-center col-12"
							style={{marginBottom: 40}}>
							<GoogleRbox reviews={reviews}
								googleWidth={24}
								googleHeight={24}
								starWidth={24}
								justifyContent={"flex-start"}
								reviewCountSize={16}
								paddingX="0"/>
						</div>
						<ReviewSlider reviews={reviews?.reviews || []}/>
					</div>
				</section>
			) : null}
		</>
	)
}

GoogleReviews.propTypes = {
	reviews: PropTypes.any
}

export default GoogleReviews
