import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import FindPerfectBikeCard from "../../home/FindPerfectBike/FindPerfectBikeCard"
import Image from "next/image"
import Link from "next/link"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

const page1Data = [
	{
		imageSrc: "/assets/images/credit-check.jpg",
		title: "creditcheck-eligible-for-financing-title",
		description: "eligible-for-financing-desc",
		btnLabel: "check-score-btn",
		btnIconSrc: "/assets/icons/credit-check-icon.svg",
		btnHref: "/creditcheck/6",
		titleWidth: "95%"
	},
	{
		imageSrc: "/assets/images/whatsapp-suggestion-pic.jpg",
		title: "custom-offer-card-title",
		description: "take-short-quiz",
		btnLabel: "take-quiz-btn",
		btnIconSrc: "/assets/icons/whats-app-button-black-icon.svg",
		btnHref: "https://api.whatsapp.com/send?phone=41435051318",
		titleWidth: "65%"
	}
]

type Props = {
    closeModal?: ()=>void
    hasCleanerLook?: boolean

}

const LeadMagnetsModalContent: React.FC<Props> = ({closeModal, hasCleanerLook}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<>
			<div className={"d-none d-md-block position-relative bg-white px-md-0 pb-5 pb-md-0"}
				style={{maxWidth: 400, width: "100%", borderRadius: "16px"}}>
				<button onClick={closeModal}
					className={"position-absolute btn p-0 top-0 end-0"}
					style={{
						marginTop: "1%",
						marginRight: "10%",
						zIndex: 99999999
					}}>
					<Image src="/assets/icons/close-icon.svg"
						width={16}
						height={16}
						alt="close"
					/>
				</button>
				<div className="leadmagnets-modal-content col-12">
					<Typography variant="heading3SmBlack"
						semanticTag="h3"
						style={{lineHeight: "28px"}}
					>{t("find-perfect-bike-title")}</Typography>
					<div className="cards-section mt-4 pt-2">
						<div className="d-flex flex-column"
							style={{rowGap: 16}}>
							{page1Data?.map(({imageSrc, title, description, btnLabel, btnIconSrc, btnHref = "/", titleWidth}, index) => (
								<div
									className="col-12 col-md-6 mb-4 mb-md-0 card-wrapper"
									style={{borderRadius: "16px", maxWidth: 460, width: "100%"}}
									key={index}>
									<div className="lead-magnet-card">
										<Link href={btnHref}>
											<a onClick={() => {
												closeModal()
												reactGA?.event({
													category: gaCategories.homePage,
													action: gaEvents.clickedOnLeadMagnet,
													label: `Click on lead magnet in popup slider - ${title}`
												})
											}}>
												<FindPerfectBikeCard
													imageSrc={imageSrc}
													title={t(title)}
													description={t(description)}
													btnLabel={t(btnLabel)}
													btnIconSrc={btnIconSrc}
													btnHref={btnHref}
													titleWidth={titleWidth}
													closeModal={closeModal}
													leadMagnetsModalDesign={hasCleanerLook}
												/>
											</a>
										</Link>
									</div>
								</div>
							))}

						</div>
					</div>
				</div>
			</div>

			<div
				className="d-md-none w-100 pt-2 px-2">
				<div className="d-flex align-items-start justify-content-between gap-4 px-2">
					<Typography variant="bodySmBold"
						semanticTag="h6"
						className="mb-0"
					>{t("find-perfect-bike-title")}</Typography>
					<button onClick={closeModal}
						className={"btn p-0"}
					>
						<Image src="/assets/icons/close-icon.svg"
							width={12}
							height={12}
							alt="close"
						/>
					</button>
				</div>

				<ul className="list-unstyled mt-2">
					{page1Data?.map(({title, btnIconSrc, btnHref = "/", titleWidth}, index) => (
						<li key={index}
							className="leadmagnet-list-item p-2">
							<Link href={btnHref}
							>
								<a onClick={() => {
									closeModal()
									reactGA?.event({
										category: gaCategories.homePage,
										action: gaEvents.clickedOnLeadMagnet,
										label: `Click on lead magnet in popup slider - ${title}`
									})
								}}
								className="link-reset d-flex justify-content-between align-items-start">
									<div className="d-flex align-items-start gap-3 col-10">
										<Image
											width={20}
											height={20}
											src={btnIconSrc}
											alt={title}
											className="col-2"
											objectFit="contain"
										/>
										<Typography variant="bodySm"
											semanticTag="span"
											className="col-10"
										>{t(title)}</Typography>
									</div>
									<Image
										width={20}
										height={20}
										src={"/assets/icons/chevron-right.svg"}
										alt={title}
									/>
								</a>
							</Link>
						</li>
					))}
				</ul>

			</div>
		</>
	)
}

export default LeadMagnetsModalContent
