import React, {useContext} from "react"
import Image from "next/image"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {Slides} from "../reusable/components/Slides"
import Button from "../reusable/components/Button"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import styles from "./home.module.scss"

export const InsuranceByCalingo = () => {
	const {t} = useTranslation(["insurance", "common"])
	const {reactGA} = useContext(AnalyticsContext)

	const onHandleGAEvent = () => {
		reactGA.event({
			category: gaCategories.homePage,
			action: gaEvents.readMoreAboutCalingo,
			label: gaEvents.readMoreAboutCalingo,
			nonInteraction: true
		})
	}

	return (
		<div className="container px-md-0 p-md-5 mt-md-5 pb-md-3">
			<div className="d-md-none">
				<Typography variant={"heading1"}
					semanticTag={"h2"}
					className="mb-2"
					style={{fontSize: 28}}>
					{t("title-1-mobile")}
				</Typography>
				<Typography variant="bodyLg"
					semanticTag="span">
					{t("subtitle-homepage")}
				</Typography>
			</div>
			<div className="d-md-none row justify-content-center align-items-center mb-5">
				<div className="mt-4">
					<div>
						<Slides centerMode
							centerModePadding={{left: 0, bottom: 0, right: 100}}>
							{insuranceBonusesList.map(({icon, text}, indx) => {
								return (
									<div key={indx++}>
										<div
											className="py-4 ms-2 px-3"
											style={{
												height: "220px",
												maxWidth: "100%",
												margin: 5,
												borderRadius: 16,
												background: "#F8F8F8"
											}}>
											<img src={icon}
												alt="icon"
												className="mb-3"
												width={26} />
											<Typography variant="bodyLg"
												semanticTag="span">
												{t(`${text}`)}
											</Typography>
										</div>
									</div>
								)
							})}
						</Slides>
						<Button
							borderWidth={0}
							paddingX="1"
							isNoUppercase
							icon={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
							color=""
							spinnerColor="black"
							href={t("/calingo")}
							className={`shadow-none ms-1 mt-1 border-0 ${styles.ctaGreyHover} ps-2 rounded-4`}
							onClick={onHandleGAEvent}
						>
							<Typography className="text-dark"
								variant="bodyLgBold">{t("read-more", {ns: "common"})}</Typography>
						</Button>
					</div>
				</div>
			</div>

			<div className="d-none d-md-block col-12 px-0">
				<div className="d-flex justify-content-end position-relative">

					<div style={{
						zIndex: 10,
						borderRadius: "16px",
						maxWidth: "40%",
						maxHeight: "80%",
						top: 0,
						bottom: 0,
						margin: "auto 0"
					}}
					className="d-flex flex-column justify-content-between px-4 pt-4
					 pt-xxl-5 pb-4 position-absolute start-0 bg-grey">
						<div className="ps-1">
							<div>
								<Typography variant="heading1Black"
									semanticTag="span"
									style={{fontSize: 36, lineHeight: "44px"}}>
									{t("title-1-homepage")}
								</Typography>
							</div>
							<Typography className="mt-xxl-2 mb-xxl-4"
								variant="bodyLg"
								semanticTag="h3">
								{t("subtitle-homepage")}
							</Typography>
							<div className={"row align-items-center"}>
								{insuranceBonusesList.map(({icon, text, id}, idx) => {
									return (
										<div className={`d-flex flex-direction-row  ps-3 ${idx !== 0 && "pt-2"}`}
											key={id}>
											<div className="col-auto">
												<Image src={icon}
													layout={"intrinsic"}
													objectFit={"contain"}
													width={24}
													height={24}/>
											</div>
											<Typography className="ms-2"
												variant="bodyLg"
												semanticTag="span">
												{t(`${text}`)}
											</Typography>
										</div>
									)
								})}
							</div>
						</div>
						<div className="d-flex justify-content-between align-items-center">
							<Button
								borderWidth={0}
								paddingX="0"
								isNoUppercase
								icon={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
								color=""
								spinnerColor="black"
								href={t("/calingo")}
								className={`shadow-none mx-0 border-0 ps-2 ${styles.ctaGreyHover}`}
								onClick={onHandleGAEvent}
							>
								<Typography className="me-2 text-dark"
									variant="bodyLgBold">{t("read-more", {ns: "common"})}</Typography>
							</Button>

							<Typography variant="heading3"
								semanticTag="span"
								style={{color: "#00686E"}}>
								{t("calingo")}
							</Typography>
						</div>
					</div>

					<div className="col-8 p-0">
						<Image src={"/assets/images/insurance-desktop-homepage-new.jpg"}
							height={568}
							width={851}
							layout="responsive"
							objectFit="cover"
							objectPosition="center"/>
					</div>

				</div>
			</div>

		</div>
	)
}

export default InsuranceByCalingo

const insuranceBonusesList = [
	{id: 1, icon: "/assets/icons/insurance/insurance-section-icon-1.svg", text: "list-text-homepage-1"},
	{id: 2, icon: "/assets/icons/insurance/insurance-section-icon-2.svg", text: "list-text-homepage-2"},
	{id: 3, icon: "/assets/icons/insurance/insurance-section-icon-3.svg", text: "list-text-homepage-3"},
	{id: 4, icon: "/assets/icons/insurance/insurance-section-icon-4.svg", text: "list-text-homepage-4"},
	{id: 5, icon: "/assets/icons/insurance/insurance-section-icon-5.svg", text: "list-text-homepage-5"}
]

