import React, {useContext, useRef, useState} from "react"
import {Slides} from "./components/Slides"
import Image from "next/image"
import Button from "./components/Button"
import Typography from "./Typography/Typography"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../context/LayoutContext"
import {useRouter} from "next/router"
import BookAppointmentButton from "./ModalButtons/BookAppointmentButton"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import {ModalContext} from "../../context/ModalContext"
import BookAppointmentModalContent from "./ModalsContent/BookAppointmentModalContent"
import {colors} from "../../colors/Colors"
import AskAiAboutMbp from "./AskAiAboutMbp"
import RyderAiStaticTile from "../bikesCollection/staticTiles/RyderAiStaticTile"
import {ArrowRight} from "react-bootstrap-icons"
import MobileModal from "./MobileModal"

function SecondaryCarouselHeroBanner() {
	const router = useRouter()
	const leadMagnets = [{
		image: "/assets/images/whatsapp-suggestion-pic.jpg",
		title: "custom-offer-card-title",
		description: "take-short-quiz",
		buttonLabel: "take-quiz-btn",
		btnHref: router?.locale === "de" ? "/suggestion/1" : "/fr/suggestion/1",
		btnIconSrc: "/assets/icons/question-mark-box.svg"

	}, {
		image: "/assets/images/book-consultation-img.jpg",
		title: "get-contacted-card-title",
		description: "talk-to-our-experts-card-desc",
		buttonLabel: null,
		btnIconSrc: null

	}, {
		image: "/assets/images/ferrari-homepage-banner.jpg",
		mobileImage: "/assets/images/ferrari-homepage-banner-mobile.jpg",
		title: "ask-ai",
		isAiBanner: true,
		description: "eligible-for-financing-desc",
		buttonLabel: "check-score-btn",
		btnIconSrc: "/assets/icons/credit-check-icon.svg",
		btnHref: null

	}]
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	const {setModalContent, openDrawer, openModal, closeModal} = useContext(ModalContext)

	const {reactGA} = useContext(AnalyticsContext)

	const handleClick = () => {
		reactGA?.event({
			category: gaCategories.consultation,
			action: gaEvents.plp,
			label: `${gaCategories.consultation} - ${gaEvents.plp}`,
			nonInteraction: false
		})

		setModalContent(<BookAppointmentModalContent closeModal={closeModal}/>)
		openModal()
	}

	const [isOpen, setIsOpen] = useState(false)
	const [input, setInput] = useState("")
	const [askedQuestion, setAskedQuestion] = useState("")
	const handleOpen = (askedQuestionParam = null) => {
		if (isMobile === true) {
			setAskedQuestion(askedQuestionParam ? askedQuestionParam : input)
		} else {
			setModalContent(<AskAiAboutMbp askedQuestion={askedQuestionParam ? askedQuestionParam : askedQuestion}

			/>)
			openDrawer()
		}

		setIsOpen(true)
	}

	const predefinedQuestions = [
		"plp-ai-predefined-question-1",
		"plp-ai-predefined-question-2",
		"plp-ai-predefined-question-3"
	]

	const inputRef = useRef(null)
	return <>
		<MobileModal modalOpen={isOpen}
			isAiModal
			handleClose={() => setIsOpen(false)} >
			<div style={{minHeight: "60dvh", overflowY: "auto"}}>
				<RyderAiStaticTile askedQuestion={askedQuestion}
					customLayoutClassname="d-flex align-items-start justify-content-start gap-3 col-12 h-100 flex-column" />
			</div>
		</MobileModal>
		{isMobile ? <div className="col-12 d-md-none home-banner-slider darker ps-3">
			<Slides dotsPositionY={-60}
				centerMode
				centerModePadding={{left: 0, bottom: 0, right: 25}}>
				{leadMagnets?.map(({image, title, description, mobileImage, buttonLabel, btnHref, isAiBanner}, index) =>
					<div onClick={() => {
						if (btnHref) {
							router.push(btnHref)
						} else if (!isAiBanner) {
							handleClick()
						}
					}}
					key={buttonLabel}
					className=" pe-2 cursor-pointer">
						<div className={"col-12 d-flex align-items-start justify-content-center p-3 mt-3"}
							style={{minWidth: "248px", minHeight: 250, borderWidth: 1, borderStyle: "solid", borderColor: "#DEE2E6", borderRadius: 16}}>
							<div className="d-flex  align-items-center justify-content-start">
								<div style={{rowGap: 8}}
									className="d-flex col-12 flex-column align-items-start justify-content-start">
									<Image src={mobileImage ? mobileImage : title === "get-contacted-card-title" ? "/assets/images/book-consultation-cropped.jpg" : image}
										height={170}
										width={350}/>
									{isAiBanner ?
										<div style={{gap: "8px", width: "18rem", zIndex: "999999999999"}}
											className="d-flex  align-items-start justify-content-start gap-3 flex-column ">
											<div
												className="d-flex align-items-start justify-content-start gap-1 col-12">
												<Image src={"/assets/icons/ryder-logo-primary.svg"}
													layout="fixed"
													height={24}
													width={24} />
												<Typography style={{fontSize: "14px", lineHeight: "20px"}}
													variant="bodySmBold">{t("ask-ai-about-bike-label")}</Typography>
											</div>
											<div style={{gap: "8px", maxWidth: "100%", overflowX: "auto"}}
												className=" d-flex  align-items-start justify-content-start ">
												{predefinedQuestions.map((question, index) => <div key={index}
													onClick={() => {
														handleOpen(t(question))
													}}
													className="predefined-ai-question">
													<Typography style={{
														fontSize: "14px",
														lineHeight: "20px", color: "#750F9B"
													}}
													variant="bodySm">{t(question)}</Typography>
												</div>)}
											</div>
											<div style={{transform: "translateZ(0)", zIndex: "99999999999"}}
												className="send-message mx-auto">
												<input
													ref={inputRef}
													onFocus={() => inputRef?.current?.focus()}
													style={{textOverflow: "ellipsis"}}
													className="input-field"
													type="text"
													value={input}
													onChange={e => setInput(e.target.value)}
													placeholder={t("ask-ai-about-us")}
													required />
												<Button
													onClick={e => {
														e.preventDefault()
														e.stopPropagation()
														handleOpen()
													}}
													type="submit"
													bgColor={"#A219D4"}
													style={{maxWidth: "20px", height: "100%"}}
													className="try-ai position-relative"
													fontSizeSmall
													icon={<ArrowRight size={24}
														color="#fff" />} />
											</div>

										</div> :
										<><Typography style={{fontSize: "16px", lineHeight: "24px", fontWeight: 700}}
											semanticTag="h1"
											variant="heading1">{t(title)}</Typography>
										<Typography style={{fontSize: "14px", lineHeight: "20px", fontWeight: 500}}
											semanticTag="h1"
											variant="heading1">{t(description)}</Typography>
										{btnHref ? <a
											onClick={() => reactGA?.event({
												category: gaCategories.secondaryHeroSection,
												action: title,
												label: t(title),
												nonInteraction: false
											})}
											target="_blank"
											href={btnHref}
											style={{color: "#212529"}}
											className="d-flex align-items-center text-decoration-none justify-content-start  py-0 px-0"
											rel="noreferrer">
											<Typography semanticTag="span"
												variant={"bodySmBlack"}
												style={{padding: "8px 0", lineHeight: "20px", fontSize: "14px"}}>{t(buttonLabel)}</Typography>
										</a> : <BookAppointmentButton
											high
											isBtnLeftAligned
											textColor="#212529"
											bgColor="transparent"
											fontSizeSmall
											color="black"
											className="mt-3"
											padding={"2px 0px"}
											label={t("Beratung Buchen")}
											iconFirst/>}</>}
								</div>
							</div>
						</div>
					</div>)}
			</Slides>
		</div> : <div style={{
			height: "362px",
			width: "100%"
		}}
		className="home-banner-slider ">
			<Slides isHomePageBannerArrows
				leftArrowsPositionX={55}
				rightArrowsPositionX={-6}
				arrowsPositionY={24}
				autoplay
				arrows
				arrowColor="white"
			>
				{leadMagnets?.map(({image, title, description, buttonLabel, btnHref, isAiBanner}, index) => {
					return <section key={buttonLabel}
						className="home-banner mb-md-0 position-relative">
						<Image className="d-none d-md-block"
							src={image}
							layout={"fill"}
							objectFit={"cover"}/>
						<div className={isAiBanner ? "gradient-purple-transparent" : "gradient-black-transparent"}/>
						{isAiBanner ? <div style={{paddingInline: "24px"}}
							className={" text-white position-relative"}>
							<div
								style={{gap: "24px"}}
								className={"d-flex home-banner-height align-items-start flex-column justify-content-end pb-4"}>
								<div className="d-flex align-items-start justify-content-start gap-1 col-12">
									<Image src={"/assets/icons/ryder-logo.svg"}
										height={24}
										width={24} />
									<Typography style={{fontSize: "14px", lineHeight: "20px"}}
										variant="bodySmBold">{t("ask-ai-about-bike-label")}</Typography>
								</div>
								<div style={{gap: "8px", maxWidth: "100%", overflowX: "auto"}}
									className=" d-flex  align-items-start justify-content-start flex-md-column">
									{predefinedQuestions.map((question, index) => <div key={index}
										onClick={() => {
											handleOpen(t(question))
										}}
										className="predefined-ai-question">
										<Typography style={{fontSize: "14px", lineHeight: "20px", color: "#750F9B"}}
											variant="bodySm">{t(question)}</Typography>
									</div>)}
									<Button bgColor={colors.primary}
										textColor="#fff"
										onClick={() => handleOpen()}
										className="try-ai position-relative"
										fontSizeSmall
										style={{marginTop: "16px"}}
										label={isMobile ? null : t("ask-something-else")}
										iconFirst={!isMobile}
										icon={
											<Image src={"/assets/icons/ryder-logo.svg"}
												width={24}
												height={24} />
										} />
								</div>

							</div>
						</div> : <div style={{paddingInline: "24px"}}
							className={" text-white position-relative"}>
							<div
								className={"row pb-5 home-banner-height align-items-end  justify-content-start"}>
								<div className={"col-12"}>
									<div className={"text-white text-left"}>
										<div className={"mb-0"}>
											<span className={"d-inline-block rounded"}>
												<Typography style={{fontSize: "20px", lineHeight: "28px", fontWeight: 900}}
													semanticTag="h1"
													variant="bodySmBold">{t(title)}</Typography>
												<Typography
													style={{
														fontSize: "16px",
														lineHeight: "24px",
														marginBottom: "16px",
														fontFamily: "Raleway"
													}}
													semanticTag="p"
													variant="bodySmBold">
													{t(description)}
												</Typography>
											</span>
										</div>
									</div>
									<div style={{marginBottom: "-1.5rem"}}
										className={"row justify-content-start mt-1"}>
										<div style={{minWidth: "280px"}}
											className={"col-12 col-md-8 col-lg-7"}>
											<div className={"d-grid banner-btn"}>
												{btnHref ? <Button label={t(buttonLabel)}
													textColor="#fff"
													color="#fff"
													borderColor="#fff"
													bgColor="transparent"
													outline={true}
													fontSizeSmall
													paddingY="2"
													className={"shadow second-banner-btn"}
													href={btnHref}/> : <BookAppointmentButton
													high
													fontSizeSmall
													outline={true}
													textColor="#fff"
													bgColor="transparent"
													color="#fff"
													padding={"2px 0px"}
													label={t("Beratung Buchen")}
													iconFirst/>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>}
					</section>
				})}
			</Slides>
		</div>}</>
}

export default SecondaryCarouselHeroBanner
