import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import BrandLogo from "../../reusable/BrandLogo"
import Button from "../../reusable/components/Button"
import Typography from "../../reusable/Typography/Typography"
import LayoutContext from "../../../context/LayoutContext"
import {Slides} from "../../reusable/components/Slides"
import styles from "./LogoGallery.module.scss"
import {formatImageSrc} from "../../../strapi/strapiHelpers"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import {useRouter} from "next/router"
import {premiumBrands} from "../../../constants"

enum bgColor {
	white = "white",
	black = "black",
	grey = "grey",
	orange = "orange"
}

interface Props {
	hideAllBrandsButton?: boolean
	bgColor?: keyof typeof bgColor
	targetHeight?: number
	col?: string
	noPadding?: boolean
	isSlider?: boolean
	brands: any
}

const LogoGallery: React.FC<Props> = ({
	brands,
	hideAllBrandsButton,
	bgColor,
	noPadding,
	isSlider
}) => {
	const {t} = useTranslation("common")
	const [loading, setLoading] = useState(false)
	const {isMobile} = useContext(LayoutContext)
	const brandsForMobile = []
	const newArrForSplice = [...brands]
	while (newArrForSplice.length) {
		if (newArrForSplice.length > 6) {
			const arrToPush = newArrForSplice.splice(0, 6)
			brandsForMobile.push(arrToPush)
		} else if (newArrForSplice.length <= 6) {
			const arrToPush = newArrForSplice.splice(0)
			brandsForMobile.push(arrToPush)
		}
	}

	const router = useRouter()
	return (
		<section
			className={`bg-${bgColor} ${noPadding ? "" : "pt-0 mx-3 pt-md-5 mt-md-4 mx-md-0 pb-3 pb-md-5"}`}
			style={{
				borderRadius: isMobile ? 16 : "",
				backgroundColor: bgColor
			}}>
			<div className={`container ${styles.wrapperRarius} ${isSlider ? "bg-grey pt-md-5 pb-3" : ""}`}
				style={{paddingTop: isMobile ? 32 : ""}}>
				{hideAllBrandsButton ? null :
					<Typography variant={`${isMobile ? "bodyLgBlack" : "heading2Black"}`}
						semanticTag="h3"
						className={"mb-3 ps-1 text-md-center"}
						style={{fontSize: isMobile ? 28 : "", lineHeight: isMobile ? "36px" : ""}}>
						{t("Wir sind offizielle Partner von:")}
					</Typography>
				}
				<div style={{minHeight: 200}}
					className="row px-0 list-unstyled justify-content-center align-items-center">
					{isSlider ?
						<>
							<div className="d-none d-md-block">
								<Slides arrowsPositionX={-2}
									dotsPositionY={-75}
									slidesToScroll={5}
									arrows
									customArrowClasses="bg-grey height-link"
									dots
									className={`px-md-5 ${styles.noListPading} dots-bg-dark partners-slider`}
									slidesToShow={5}>
									{
										brands.map((brand, index) => {
											const {link, name, image} = brand
											const isPremiumBrand = premiumBrands.includes(link)

											// Adapt this to Strapi
											if (image) {
												const imageSrc = image ? formatImageSrc(image.data.attributes.url) : ""
												return <BrandLogo col={"col-auto px-4"}
													logoBrandName={name}
													key={`${link}_${index}`}
													src={imageSrc}
													width={120}
													height={80}
													href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${link}` : ` /fr/brand-world/${link}` : filterUrl({[urlParams.brand]: link}, urlParams.brand, link)}
													customStyles={"grey-hover px-2 py-1 d-flex " +
														"justify-content-center align-items-center rounded-4"}
													isMinHeight/>
											}

											return null
										})
									}
								</Slides>
							</div>

							<div className="d-md-none birder">
								<Slides dotsPositionY={-70}
									dots
									className={`flex-wrap ms-0 mb-5 
									${styles.noListPadind} dots-bg-dark partners-slider`}
									slidesToShow={1}
									slidesToScroll={1}>
									{
										brandsForMobile.map((item, index) => {
											return <div
												className="d-flex flex-wrap justify-content-between align-items-center"
												key={index}> {item.map((brand, index) => {
													const {link, name, image} = brand
													const isPremiumBrand = premiumBrands.includes(link)

													// Adapt this to Strapi
													if (image) {
														const imageSrc = image ?
															formatImageSrc(image.data.attributes.url) : ""
														return <BrandLogo col={"col-5"}
															logoBrandName={name}
															key={`${link}_${index}`}
															src={imageSrc}
															width={110}
															height={100}
															href={isPremiumBrand ? router.locale === "de" ? ` /brand-world/${link}` : ` /fr/brand-world/${link}` : filterUrl({[urlParams.brand]: link}, urlParams.brand, link)}
															customStyles={"grey-hover px-2 py-1 d-flex " +
																"justify-content-center align-items-center rounded-4"}
															isMinHeight/>
													}

													return null
												})}
											</div>
										})
									}
								</Slides>
							</div>

						</> :

						brands.slice(0, 12).map(brand => {
							const {slug, menuLabel, icon} = brand.fields
							if (icon) {
								const {width, height, image} = icon
								const imageSrc = image ? formatImageSrc(image.data.attributes.url) : ""
								const targetHeight = 35
								const targetWidth = targetHeight ? targetHeight * (width / height) : 140
								const aspectRatio = width / height
								const aspectHeight = targetWidth / aspectRatio
								return <BrandLogo col={"col-auto px-4"}
									logoBrandName={menuLabel}
									key={slug}
									src={imageSrc}
									width={120}
									height={80}
									href={`/${t("brands")}/${slug}`}
									customStyles={"grey-hover px-2 py-1 d-flex " +
										"justify-content-center align-items-center rounded-4"}
									isMinHeight/>
							}

							return null
						})
					}
					{hideAllBrandsButton ? null : <div className={"col-12 mt-md-3"}>
						<div className="row justify-content-center text-center">
							<div className="col-12"
								style={{marginTop: isMobile && !isSlider ? 28 : ""}}>
								{isSlider ? <div className="col-12 text-start text-md-center">
									<Button
										paddingX="0"
										isNoUppercase
										icon={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
										// IconOnHover={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
										color=""
										spinnerColor="black"
										href={t("/alle-marken")}
										className="px-0 mx-0 grey-hover"
										isLoading={loading}>
										<Typography className="me-2 ps-1"
											variant="bodyLgBold">{t("alle-marken")}</Typography>
									</Button> </div> :

									<Button label={t("alle-marken")}
										outline
										href={"/alle-marken"}
										borderWidth={1}
										textColor="#212529"
										color="#fff"
										hoverColor="#212529"
										className={"col-12 col-md-4"}
										isLoading={loading}
										onClick={() => {
											setLoading(true)
										}}/>
								}
							</div>
						</div>
					</div>}
				</div>
			</div>
		</section>
	)
}

export default LogoGallery
