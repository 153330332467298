import React, {useContext, useEffect, useRef, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import Drawer from "../reusable/components/Drawer/Drawer"
import LeadMagnetsModalContent from "../reusable/ModalsContent/LeadMagnetsModalContent"
import {ModalContext} from "../../context/ModalContext"
import {useTranslation} from "next-i18next"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"

const localStorageItemName = "modal-last-time--trigger"
// Const _MS_PER_DAY = 1000 * 60
const _MS_PER_DAY = 1000 * 60 * 60 * 24

const saveToLocalStorage = (time:Date) => {
	if (typeof window !== "undefined") {
		localStorage.setItem(localStorageItemName, JSON.stringify(time))
	}
}

function dateDiffInDays(a:Date, b:Date): number {
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes())
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes())

	return (utc2 - utc1) / _MS_PER_DAY
	// Return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

const getTimeFromLastOpen = (currentTime: Date): number => {
	let lastTimeOpen
	if (typeof window !== "undefined") {
		lastTimeOpen = JSON.parse(localStorage.getItem(localStorageItemName))
	}

	if (lastTimeOpen) {
		const difference = dateDiffInDays(new Date(lastTimeOpen), currentTime)
		return difference
	}

	return -1
}

const BottomStickyBar = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [showStickyBar, setShowStickyBar] = useState(false)
	const {t} = useTranslation(["common"])
	const {closeModal} = useContext(ModalContext)
	const {reactGA} = useContext(AnalyticsContext)
	const timer = useRef<ReturnType<typeof setTimeout>>()

	const cancelTimeout = () => {
		if (timer.current) {
			clearTimeout(timer.current)
		}
	}

	const [scrollHeight, setScrollHeight] = useState<number | null>(null)

	const handleScroll = () => {
		if ((document.documentElement.clientWidth + window.pageYOffset) >= scrollHeight) {
			window.removeEventListener("scroll", handleScroll)
			cancelTimeout()
			startTimeout(0)
		}
	}

	useEffect(() => {
		setScrollHeight(Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight
		))
	}, [])

	const startTimeout = (duration: number = 7000) => {
		const timeFromLastOpen = getTimeFromLastOpen(new Date())

		if (timeFromLastOpen === -1 || timeFromLastOpen >= 1) {
			timer.current = setTimeout(() => {
				saveToLocalStorage(new Date())
				setShowStickyBar(true)
			}, duration)
		}
	}

	useEffect(() => {
		if (scrollHeight) {
			startTimeout(10000)

			if (scroll) {
				window.addEventListener("scroll", handleScroll)
			}

			return () => {
				if (scroll) {
					window.removeEventListener("scroll", handleScroll)
				}

				cancelTimeout()
			}
		}
	}, [scrollHeight])

	const handleClose = () => {
		setIsDrawerOpen(false)
		setShowStickyBar(false)
		closeModal()

		reactGA?.event({
			category: gaCategories.homePage,
			action: gaEvents.closeBottomStickyBar,
			label: "Close icon clicked"
		})
	}

	const handleOpen = () => {
		setIsDrawerOpen(true)

		reactGA?.event({
			category: gaCategories.homePage,
			action: gaEvents.openSlider,
			label: "Click here button clicked to open popup slider"
		})
	}

	return (
		<>
			<section
				className={`bottom-sticky-bar text-md-center w-100 d-flex justify-content-md-center position-relative 
                    ${showStickyBar ? "slide-up" : "slide-down"}`}>
				{/* Desktop */}
				<div className="d-none d-md-block">
					<div className="d-md-flex gap-2 align-items-center">
						<Typography semanticTag="h4"
							className="mb-0"
							variant="bodySmBold" >
							{t("find-perfect-bike-title")}
						</Typography>
						<button style={{backgroundColor: "#FFE9C2", border: "none", lineHeight: "20px"}}
							className="rounded-3"
							onClick={handleOpen}
						>
							<Typography semanticTag="span"
								variant="bodySmBold"
							>
								{t("click-here")}
							</Typography>
						</button>
					</div>
					<button style={{border: "none", background: "transparent", position: "absolute", bottom: "15px", right: "74px"}}
						className="d-flex align-items-center"
						onClick={handleClose}
					>
						<Image
							src={"/assets/icons/close-icon.svg"}
							width={12}
							height={12}
							alt="close"
						/>
					</button>
				</div>

				{/* Mobile */}
				<div className="d-flex d-md-none gap-2 align-items-center col-12">
					<LeadMagnetsModalContent closeModal={handleClose}/>
				</div>
			</section>
			<Drawer closeOnTouch
				isOpen={isDrawerOpen}
				closeModal={() => setIsDrawerOpen(false)}
				hasShadow={false}>
				<LeadMagnetsModalContent
					closeModal={handleClose}
					hasCleanerLook/>
			</Drawer>
		</>
	)
}

export default BottomStickyBar
