import React, {useContext} from "react"
import {Clock, GeoAlt} from "react-bootstrap-icons"
import Typography from "../../reusable/Typography/Typography"
import Button from "../../reusable/components/Button"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"

function ShowroomHomepageSection() {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	const contact = [{icon: () => <GeoAlt />, label: "Zweierstrasse 100, 8003 Zurich"}, {icon: () => <Clock />, label: t("opening-hours-label")}, {
		icon: () => <Image src={"/assets/icons/coffee.svg"}
			width={16}
			height={16}
			layout="fixed" />, label: t("coffee-on-the-house")
	}]
	return (

		<div
			className=" container my-5 position-relative d-flex align-items-center justify-content-start">
			<div style={{display: isMobile ? "none" : ""}}
				className="col-8 p-0 ">
				<Image style={{borderRadius: "4px"}}
					src={"/assets/images/showroom-desktop.jpg"}
					height={568}
					width={851}
					layout="responsive"
					objectFit="cover"
					objectPosition="center"/>
			</div>

			<div style={{right: 0, position: isMobile ? "relative" : "absolute"}}
				className="showroom-header">
				<div style={{display: isMobile ? "" : "none"}}
					className="col-12 p-0 ">
					<Image style={{borderRadius: "4px"}}
						src={"/assets/images/showroom-desktop.jpg"}
						height={568}
						width={851}
						layout="responsive"
						objectFit="cover"
						objectPosition="center"/>
				</div>
				<section style={{gap: "16px"}}
					className="d-flex align-items-start  justify-content-start flex-column">
					<Typography variant="bodySmBlack"
						style={{fontSize: "36px", lineHeight: "44px", maxWidth: "488px"}}>{t("visit-showroom-title")}</Typography>
					<Typography variant="bodySm"
						style={{fontSize: "14px", lineHeight: "20px"}}>{t("bnpl-showroom-description")}</Typography>

					<div style={{marginTop: "24px"}}
						className="d-flex align-items-start justify-content-start gap-4 flex-column">
						<Typography variant="bodySmBlack"
							style={{fontSize: "28px", lineHeight: "36px"}}>{t("mbp-showroom-label")}</Typography>
						<div className="d-flex align-items-start justify-content-start gap-3 flex-column">
							{contact.map(({icon, label}, index) => <div key={index}
								className="d-flex align-items-center gap-3 justify-content-center">
								{icon()}
								<Typography variant="bodySm"
									style={{fontSize: "14px", lineHeight: "20px", color: "#212529"}}>{label}</Typography>
							</div>)}
						</div>
						<Button
							fontSizeSmall
							bgColor="#FEC04D"
							href={t("showroom-brand-world-link")}
							className="col-12 col-md-auto "
							label={t("signup-for-testdrive-label")} />
					</div>
				</section>
			</div>
		</div>
	)
}

export default ShowroomHomepageSection
