import React, {useContext} from "react"
import BlockText from "../reusable/render/BlockText"
import LayoutContext from "../../context/LayoutContext"
import {useRouter} from "next/router"

const HomeTextComponent = () => {
	const {isMobile} = useContext(LayoutContext)
	const {locale} = useRouter()
	const content = homeTextComponentData[locale]

	return (
		<section>
			<div className={`container ${isMobile ? "mt-2 pt-3" : "mt-3 pt-2"}  mb-5`}>
				<div className="position-relative row">
					<div className={`col-12 ${!isMobile && "text-center"}  text-md-start`}>
						{content.map((fields, idx) => {
							return (
								<div key={idx}>
									<BlockText textClassname={"mb-0"}
										homeText={true}
										fields={fields}/>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</section>
	)
}

export default HomeTextComponent

const homeTextComponentData = {
	de: [{
		title: "",
		collapsible: true,
		text: {
			content: [
				{
					content: [{value: "Wofür MyBikePlan steht"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "MyBikePlan bietet dir die Möglichkeit, ein E-Bike zu kaufen und mit zinsfreier Ratenzahlung zu bezahlen. Ganz nach unserem Motto: Bike now, pay later. Den Zahlungsplan kannst Du individuell gestalten und die Laufdauer zwischen 12-48 Monaten festlegen.\n" +
							"So unterstützen wir dich beim Wechsel zur Zweirad-E-Mobilität während Du dein Erspartes weiterhin sinnvoll einsetzen kannst."}],
					nodeType: "paragraph"
				},
				{
					content: [{value: "Was eine 0% Finanzierung ausmacht"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "Mit der 0% Finanzierung ermöglichen wir dir eine zinsfreie Ratenzahlung. Der Preis des E-Bikes bleibt somit immer derselbe. Vergleichsweise bezahlt man bei der üblichen Ratenzahlung und Leasingoptionen einen bestimmten Zinssatz, um den sich der Gesamtpreis dann erhöht. Bei uns ist das hingegen nicht der Fall."}],
					nodeType: "paragraph"
				},
				{
					content: [{value: "Wie MyBikePlan funktioniert"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "Der Verkauf erfolgt über unsere Website mybikeplan.ch. Nachdem Du dein Wunsch-E-Bike bestellt hast, nehmen wir eine Bonitätsprüfung für dich vor. Ist diese erfolgreich verlaufen, senden wir dir den Vertrag direkt und unkompliziert per Email zu. Im nächsten Schritt erhältst Du einen Anruf von unserem Team, um den weiteren Ablauf zu besprechen und offene Fragen zu klären. Bist Du mit allem einverstanden, kannst Du den Vertrag digital unterzeichnen. Danach werden wir dein E-Bike für dich reservieren und entsprechend der angegebenen Verfügbarkeit die Auslieferung organisieren. Bei allen Fragen stehen wir dir aber jederzeit persönlich zur Verfügung. Gerne unterstützen wir dich bei der Suche nach dem E-Bike, das perfekt zu dir und deinen Bedürfnissen passt. Rufe uns dazu gerne an (0435051318)."}],
					nodeType: "paragraph"
				},
				{
					content: [{value: "Montage und Heimlieferung"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "Die Montage deines Elektrovelos nehmen wir für dich vor und liefern es fahrbereit zu dir nach Hause. Unser Spediteur wird dich vor der Auslieferung kontaktieren, um den Termin zu vereinbaren."}],
					nodeType: "paragraph"
				}
			]
		}
	}],
	fr: [{
		title: "",
		collapsible: true,
		text: {
			content: [
				{
					content: [{value: "Ce que représente MyBikePlan"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "MyBikePlan t’offre la possibilité d'acheter un E-Bike et de le payer en plusieurs versements sans intérêt. Tu peux personnaliser le plan de paiement et fixer la durée entre 12 et 48 mois. De cette manière, nous t’aidons à passer à l'e-mobilité à deux roues tout en te permettant de continuer à faire bon usage de tes économies."}],
					nodeType: "paragraph"
				},
				{
					content: [{value: "Ce qui rend le financement à 0 % si spécial"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "Avec le financement à 0 %, nous te proposons des paiements mensuels sans intérêt. Le prix de l'E-Bike reste donc toujours le même. En comparaison, avec les paiements mensuels et les options de leasing habituels, tu payes un certain taux d'intérêt, par lequel le prix total augmente ensuite. Avec nous, cependant, ce n'est pas le cas."}],
					nodeType: "paragraph"
				},
				{
					content: [{value: "Comment cela fonctionne"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "La vente a lieu via notre site web mybikeplan.ch. Une fois que tu as commandé le vélo électrique de ton choix, nous effectuons une vérification de ta solvabilité. En cas de succès, nous t’enverrons le contrat directement et simplement par e-mail. À l'étape suivante, tu recevras un appel de notre équipe pour discuter du reste du processus et clarifier toute question en suspens. Si tu es d'accord avec tout, tu peux signer le contrat numériquement. Ensuite, nous réservons ton E-Bike pour toi et organisons la livraison en fonction de la disponibilité indiquée. Nous sommes toujours disponibles pour répondre à tes questions en personne. Nous serons heureux de t’aider à trouver l'E-Bike qui te convient parfaitement et qui répond à tes besoins. Appelle-nous (0435051318)."}],
					nodeType: "paragraph"
				},
				{
					content: [{value: "Montage et livraison à domicile"}],
					nodeType: "heading-4"
				},
				{
					content: [{value: "Nous assemblerons ton E-Bike pour toi et le livrerons à ton domicile prêt à rouler. Notre transporteur te contactera avant la livraison pour fixer le rendez-vous."}],
					nodeType: "paragraph"
				}
			]
		}
	}]
}
