import React, {useContext} from "react"
import Link from "next/link"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import Image from "next/image"

type Props = {
  src: string,
  href: string,
  width?: number,
  height?: number,
  logoBrandName: string
  col?: string,
  customStyles?: string,
  isMinHeight?: boolean
}

const BrandLogo: React.FC<Props> = ({src, href, logoBrandName, col = "col-6 col-lg-3", width = 120, height = 80, customStyles = "", isMinHeight}) => {
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div className={`${col} text-center`}>
			<Link href={href}>
				<a style={{minHeight: isMinHeight ? "40px" : ""}}
					className={customStyles}
					onClick={() => {
						reactGA?.event({
							category: gaCategories.homePage,
							action: gaEvents.partnersLogosSection,
							label: `${gaEvents.partnersLogosSection} Clicked ${logoBrandName}`,
							nonInteraction: false
						})
					}}>
					<Image src={src}
						alt="brand logo"
						height={height}
						width={width}
						objectFit={"contain"}/>
				</a>
			</Link>
		</div>
	)
}

export default BrandLogo
