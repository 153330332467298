import React, {useContext} from "react"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import BlogsCollectionItem from "../blogsCollection/blogsCollectionItem/BlogsCollectionItem"
import {Slides} from "./components/Slides"
import LayoutContext from "../../context/LayoutContext"
import Typography from "./Typography/Typography"
import Button from "./components/Button"
import AnalyticsContext from "../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"

type Props = {
  posts: any[]
}

const BlogsComponent: React.FC<Props> = ({posts}) => {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<section className={`${isMobile ? "pt-3" : "pt-4"}  pb-4 block-blog`}>
			<div className={"container d-none d-md-block"}>
				<div className="row mt-5 mb-5 justify-content-center">
					<Typography variant={isMobile ? "heading2" : "heading2Black"}
						className="text-black-med text-bold text-center mb-5">{t("Blog")}</Typography>
					<div className="row gx-4 justify-content-center">
						{posts?.map(post => {
							return (
								<div className="col-4"
									key={post.id}>
									<BlogsCollectionItem post={post}
										height={260}/>
								</div>
							)
						})
						}
					</div>
					<div className="col-12 text-center mt-5">
						<div className="row justify-content-center">
							<div className="col-6">
								<Button label={t("weitere Artikel")}
									outline
									color="#fff"
									hoverColor="#212529"
									bgColor="#fff"
									onClick={() => {
										reactGA?.event({
											category: gaCategories.homePage,
											action: gaEvents.allBlogsButtonClicked,
											label: `${gaEvents.allBlogsButtonClicked}`,
											nonInteraction: false
										})
									}}
									textColor="#212529"
									href={"/blog"}
									className="w-auto"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={"d-block d-md-none justify-content-center"}
				style={{overflowX: "hidden"}}>
				<Typography className="ps-3 col-12"
					variant={"bodyLgBlack"}
					style={{fontSize: 28, marginBottom: 32}}>{t("Blog")}</Typography>
				<div className="col-12 col-lg-8 blogs-slider-wrapper ps-3">
					<Slides centerMode
						centerModePadding={{left: 0, bottom: 0, right: 25}}>
						{Array.isArray(posts) ? posts.map((post, i) => {
							if (i < 3) {
								return (
									<div key={post.id}
										className="row g-2">
										<div key={post.id}
											className={"col-12 col-md-6 col-lg-4 d-flex justify-content-center mt-3"}
											style={{maxWidth: "288px"}}>
											<BlogsCollectionItem key={post.id}
												post={post}
												isSliderItem={false}
												width={"288px"}
												height={192}/>
										</div>
									</div>
								)
							}

							return null
						}) : null}
					</Slides>

				</div>
				<div className={`col-12 container text-center ${isMobile ? "mt-4" : "mt-5"}`}>
					<div className={"row justify-content-center"}>
						<div className={`${isMobile ? "col-12" : "col-6"}`}>
							<Button label={t("weitere Artikel")}
								outline
								color="#fff"
								paddingY="3"
								onClick={() => {
									reactGA?.event({
										category: gaCategories.homePage,
										action: gaEvents.allBlogsButtonClicked,
										label: `${gaEvents.allBlogsButtonClicked}`,
										nonInteraction: false
									})
								}}
								hoverColor="#212529"
								bgColor="#fff"
								textColor="#212529"
								href={"/blog"}
								className="col-12"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

BlogsComponent.propTypes = {
	posts: PropTypes.array.isRequired
}

export default BlogsComponent
