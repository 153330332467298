/* eslint-disable prefer-destructuring */
import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import Typography from "../reusable/Typography/Typography"
import {getNumberOfStars, getStars} from "../../utility/Helper"
import {useTranslation} from "next-i18next"

type Props = {
  review: any,
  _key?: any,
  transparent?: boolean,
  grey?: boolean,
  desktop?: boolean,
  PDP?: boolean
  className?: string
}

const GoogleReviewsItem: React.FC<Props> = ({review, _key, transparent, grey, desktop, PDP, className}) => {
	const ref = useRef<HTMLDivElement>()
	const {t} = useTranslation()

	let date = review?.updateTime.split("T")
	date = date?.[0]?.split("-")
	date = `${date?.[2]}/${date?.[1]}/${date?.[0]}`

	const [showArrow, setShowArrow] = useState(false)
	const heightSize = PDP ? "70px" : "96px"
	const [height, setHeight] = useState(heightSize)

	const strtsNbr = getNumberOfStars(review?.starRating)
	const stars = getStars(strtsNbr)

	useEffect(() => {
		const current = ref.current
		if (current?.clientHeight < current?.scrollHeight) {
			setShowArrow(true)
		}
	}, [ref])

	return (
		<div key={_key}
			className={`${PDP ? "col-11" : "col-12"} ${desktop ? "col-md-10" : "col-md-4"} ${className ? className : ""}`}>
			<div className={`${transparent ? "px-1" : "rounded border border-1 text-start py-4 px-3"} position-relative"`}>
				<div ref={ref}
					className="overflow-hidden position-relative"
					style={{height}}>
					<div>
						<Typography id={review?.reviewId}
							variant="bodyLg"
							semanticTag="p"
							className={`${grey && "text-grey"}`}
							style={{marginBottom: 0}}>
							{t(`"${review?.comment}"`)}
						</Typography>
					</div>
				</div>
				{showArrow && <div className="d-flex  align-items-center mt-3"
					onClick={() => {
						if (height === heightSize) {
							setHeight("auto")
						}

						if (height === "auto") {
							setHeight(heightSize)
						}
					}}>
					<Typography variant="bodyLgBold"
						semanticTag="span"
						style={{fontSize: 14}}>Show {height === heightSize ? "more" : "less"}</Typography>
					<div className="d-flex justify-content-center align-items-center"
						style={{marginLeft: 10}}>
						{height === heightSize ? <img src="/assets/icons/chevron-down.svg"
							alt="arrow down"/> :
							<img src="/assets/icons/chevron-up.svg"
								alt="arrow down"/>
						}
					</div>
				</div>}

				<div className={"my-4 my-md-0 mt-md-4"}>
					<div className="col-auto text-start">
						<Typography variant="bodyLgBold"
							semanticTag="span"
							style={{fontSize: 14}}
							className=" w-auto">{review?.reviewer.displayName}</Typography>
						<Typography variant={"bodySm"}
							semanticTag="span"
							style={{fontSize: 14, marginLeft: 12, color: "#6C7074"}}>{date}</Typography>
					</div>
					<div className="col-auto d-block pt-1">
						<div className="row justify-items-center g-0">
							{stars.map(star => {
								return star
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

GoogleReviewsItem.propTypes = {
	review: PropTypes.any,
	_key: PropTypes.any
}

export default GoogleReviewsItem
